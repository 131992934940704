ol {
    counter-reset: item;
}

ol > li {
    counter-increment: item;
}

ol ol > li {
    display: block;
}

ol ol > li:before {
    content: counters(item, ".") ". ";
    margin-left: -20px;
}
